export const config = {
	firebaseConfig: {
		apiKey: "AIzaSyB5z7idSIvuJinVY4R0-yY3ySU1LEJaBlY",
		authDomain: "daily-rainfall.firebaseapp.com",
		databaseURL: "https://daily-rainfall-default-rtdb.firebaseio.com/",
		projectId: "daily-rainfall",
		storageBucket: "daily-rainfall.appspot.com",
		messagingSenderId: "916245816924",
		appId: "1:916245816924:web:e05bce5915f02ac965e899",
		measurementId: "G-3VBDW6NHG3"
	}
}
